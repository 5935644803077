import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBIkrzYn9zV-8LdcSKlAGpCeZKFqDuAoio",
  authDomain: "natchnienie-studio-48cbe.firebaseapp.com",
  projectId: "natchnienie-studio-48cbe",
  storageBucket: "natchnienie-studio-48cbe.firebasestorage.app",
  messagingSenderId: "123496882507",
  appId: "1:123496882507:web:b8f10f8481693049bb08ce",
  measurementId: "G-4RXHDVPXMS"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
